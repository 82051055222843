<template>
  <div class="orders">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <p class="main-header-section">
        Заявки
        <span
          v-if="
            !Orders.is_loading &&
            Orders.filters.select !== 'today' &&
            Orders.filters.select !== 'yesterday'
          "
        >
          с {{ Orders.o_start }} по {{ Orders.o_end }}
        </span>
      </p>
      <div class="download-outline-btn">
        <feather-icon icon="DownloadIcon" style="margin-right: 8px" size="18" />
        <span>Сохранить отчет</span>
      </div>
    </div>

    <filters-view />

    <table-view :Orders="Orders" />

  </div>
</template>

<script>
import Offers from "@/modules/offers/";
import Orders from "@/modules/admin/orders";

import { ValidationProvider, ValidationObserver } from "vee-validate";

import FiltersView from "../widgets/filters";
import tableView from "../tables/tablet";

export default {
  data() {
    return {
      Offers,
      Orders,
    };
  },
  props: {},
  methods: {
    closeModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });
    },
    showModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });

      if (this.modalsVisibility.hasOwnProperty(action)) {
        this.modalsVisibility[action] = true;
      }
    },
    loadMore() {},
    changeActiveCategories(categories) {},
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    FiltersView,
    tableView,
  },

  computed: {},

  watch: {},
  mounted() {
    this.$router.replace({ query: {} });
    Orders.list();
  },
};
</script>

<style lang="scss">
.orders {
  @media screen and (max-width: 1128px) {
    .card-title {
      margin-bottom: 32px;
    }

    .vs__search,
    .vs__search:focus {
      font-size: 16px;
    }

    .col {
      padding-right: 0;

      &:last-child {
        padding-left: 39px;
      }
    }

    .form-group {
      padding: 4px 20px !important;
      margin: 0 !important;
    }
  }
}

.orders-table {
  .item-offer-button-edit {
    @media screen and (max-width: 1128px) {
      margin: 0 4px 0 0;
    }
  }

  .item-offer-button-edit,
  .item-offer-button-delete {
    @media screen and (max-width: 1128px) {
      font-weight: 600;
      line-height: 20px;
    }
  }

  .item-offer-title {
    font-weight: 500;
    line-height: 22px;
    font-size: 18px;
  }

  .item-details {
    .details-row {
      border-bottom: 1px solid #eff1f4;
      padding: 12px 0;

      &:last-child {
        border: 0;
      }

      .details-name {
        color: #787e8e;
        line-height: 20px;
        font-size: 14px;

        @media screen and (max-width: 744px) {
          font-size: 12px;
          line-height: 16px;
          max-width: 100px;
        }
      }

      .details-value {
        color: #1e2041;
        font-size: 16px;
        line-height: 24px;
        max-width: 400px;
        text-align: right;

        @media screen and (max-width: 744px) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
}

.dark-layout {
  .orders-table {
    .item-details {
      .details-value {
        color: white;
      }
    }
  }
}
</style>
