

    import $request from "@/modules/request/"

    export default {

        items: [],
        last_page: 0,
        total_count: 0,
        current_page: 1,
        has_more_pages: false,

        filters: {
            select: 'today',
            period: {
                start: '',
                end: ''
            },
            product: null,
            status: null,
            user: ''
        },

        o_start: '',
        o_end: '',

        is_loading: false,

        list(page = 1, cb = null) {
            this.is_loading = true;
            this.filters.page = page;
            $request.get("orders/list", this.filters ).then( rsp => {
                this.items = rsp.items;
                this.last_page = rsp.last_page;
                this.total_count = rsp.total_count;
                this.current_page = rsp.current_page;
                this.has_more_pages = rsp.has_more_pages;

                this.o_start = rsp.o_start;
                this.o_end = rsp.o_end;

                if (cb) cb();

                this.is_loading = false;
            });
        },

       
    }
