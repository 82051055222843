<template>
  <div class="orders">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <p class="main-header-section">
        <span class="d-block">Заявки</span>
        <span
          v-if="
            !Orders.is_loading &&
            Orders.filters.select !== 'today' &&
            Orders.filters.select !== 'yesterday'"
        >
          с {{ Orders.o_start }} по {{ Orders.o_end }}
        </span>
      </p>
      <div class="download-outline-btn">
        <feather-icon icon="DownloadIcon" style="margin-right: 8px" size="18" />
        <span>Сохранить отчет</span>
      </div>
    </div>

    <filters-view />

    <table-view :Orders="Orders" />

  </div>
</template>

<script>
import Offers from '@/modules/offers/';
import Orders from '@/modules/admin/orders';

import { ValidationProvider, ValidationObserver } from 'vee-validate';

import FiltersView from '../widgets/filters';
import tableView from '../tables/mobile';

export default {
  data() {
    return {
      Offers,
      Orders,
    };
  },
  props: {},
  methods: {
    closeModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });
    },
    showModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });

      if (this.modalsVisibility.hasOwnProperty(action)) {
        this.modalsVisibility[action] = true;
      }
    },
    loadMore() {},
    changeActiveCategories(categories) {},
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    FiltersView,
    tableView,
  },

  computed: {},

  watch: {},
  mounted() {
    this.$router.replace({ query: {} })
    Orders.list();
  },
};
</script>

<style lang="scss">
.orders {
  @media screen and (max-width: 744px) {
    .card-title {
      margin-bottom: 26px;
    }
  }
}
</style>
